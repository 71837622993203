.hero-acheter {
	width: 100%;
	background-size: cover;

	text-align: center;
	padding: 15vw 50px;
	overflow: hidden;
	position: relative;

	.bigtitle {
		font-size: 60px;
		color: $colorAccent1;
		position: relative;
		top: -4vw;
	}

	.bg-element {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;

		background-image: url('/img/hero-acheter.jpg');
		background-size: cover;
		background-position: center bottom;
	}

	@media only screen and (max-width: 940px) {
		padding: 20vw 50px;
		.bigtitle {
			font-size: 45px;
		}
	}
	@media only screen and (max-width: 550px) {
		.bigtitle {
			font-size: 30px;
		}
	}
}

.drive-to-store-wrap {
	padding: 0 9vw;
	@media only screen and (max-width: 550px) {
		padding: 0 20px;
	}
}

.drive-to-store {
	position: relative;
	z-index: 5;
	width: 100%;
	display: flex;
	background-color: #fff;
	margin: 0 auto;
	padding: 5vw;
	margin-top: -10vw;
	border-radius: 35px;

	.points-vente-wrap {
		width: 50%;
		padding-right: 50px;

		.search-form {
			display: flex;
			justify-content: center;
			align-items: stretch;
			width: 100%;

			img {
				width: auto;
				height: 60px;
			}

			input[type="text"] {
				padding: 20px;
				width: 200px;
				height: 60px;
				border:0;
				font-family: $fontText;
				background-color: #EEEEEE;
				-webkit-appearance: none;
				border-radius: 0;
			}

			input[type="submit"] {
				width: 70px;
				height: 60px;
				border: 0;
				background-color: transparent;
				background-image: url('/img/input-submit.svg');
				background-size: 70px auto;
				background-repeat: no-repeat;
				cursor: pointer;
				-webkit-appearance: none;
				border-radius: 0;
			}
		}

		.smalltitle {
			width: 100%;
			text-align: center;
			padding-bottom: 30px;
			margin-top: 50px;
			border-bottom: 1px solid #E4E4E4;
		}

		.points-vente {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			width: 100%;
			height: 400px;
			overflow-y: scroll;

			.point {
				display: flex;
				align-items: center;
				padding-top: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid #E4E4E4;
				cursor: pointer;
				transition: all 0.2s ease;

				min-height: 90px;
				width: 100%;

				&:hover,
				&.is-active {
					background-color: #E4E4E4;
				}

				img {
					width: 80px;
					height: auto;
					padding-left: 20px;
				}

				.data {
					width: calc(100% - 100px);
					padding-left: 20px;
					font-family: $fontText;
					font-size: 14px;

					.name {
						margin-bottom: 5px;
					}
				}
			}
		}
	}

	.google-map {
		width: 50%;
	}

	@media only screen and (max-width: 960px) {
		flex-wrap: wrap;
		.points-vente-wrap {
			width: 100%;
			padding-right: 0;
		}
		.google-map {
			width: 100%;
		}
	}

	@media only screen and (max-width: 550px) {
		margin-top: 50px;
		padding: 0;
		.google-map {
			margin-bottom: 50px;
			margin-top: 30px;
		}
	}
}
