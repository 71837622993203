.main-footer-wrap {
  width: 100%;
  background-image: url('/img/footer-background.jpg');
  background-size: cover;
  text-align: center;
  padding: 100px 50px 50px 50px;

  .logo-footer {
    width: 20vw;
    max-width: 200px;
  }

  .mediumtitle {
    margin: 60px 0;
  }

  .epiceries-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 10vw;

    img {
      width: auto;
      height: 45px;
      margin: 20px 25px;
    }

    a {
      text-decoration: none;
    }

    span.label {
      font-family: $fontTitle;
      font-size: 14px;
      line-height: 1.1;
      color: $colorAccent2;
      text-decoration: none;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;

    p {
      width: 100%;
      text-align: center;
    }
  }

  .social {
    display: flex;

    img {
      width: 30px;
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: 1285px) {
    .epiceries-wrap {
      flex-wrap: wrap;
      img {
        height: 40px;
      }
    }
  }
  @media only screen and (max-width: 950px) {
    padding: 50px;
    .epiceries-wrap {
      img {
        height: 30px;
      }
    }
  }
  @media only screen and (max-width: 620px) {
    padding: 40px;
    .epiceries-wrap {
      padding: 0;
      img {
        height: 25px;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    padding: 30px 20px;
    .epiceries-wrap {
      padding: 0;
      img {
        height: 20px;
      }
    }
    .mediumtitle {
      margin: 30px 0 40px 0;
    }
    .bottom {
      flex-wrap: wrap;
      justify-content: center;
      p {
        width: 100%;
      }
      .social {
        width: 100%;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}

.footer_credits {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  .regulartext {
    font-size: 14px;
  }

  a {
    text-decoration: none;
    color: $colorAccent1;
    font-size: 14px;
  }

  @media only screen and (max-width: 620px) {
    flex-wrap: wrap;

    .regulartext {
      width: 100%;
      margin-bottom: 20px;
    }

    a {
      width: 100%;
    }
  }
}

.politique_wrap {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding: 10vw 5vw;

  .mediumtitle {
    margin-bottom: 30px;
  }

  .smalltitle {
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .regulartext {
    margin-bottom: 20px;
  }
}
