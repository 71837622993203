.hero-wrap {
	padding: 0 50px;

	@media only screen and (max-width: 550px) {
		padding: 0 20px;
	}
}

.hero-home {
	width: 100%;
	text-align: center;
	padding: 20vw 50px;
	overflow: hidden;
	position: relative;

	

	.bigtitle {
		position: absolute;
		left: 25px;
		bottom: 25px;
		font-size: 50px;
		color: #fff;
		text-align: left;
	}

	.main-button {
		position: absolute;
		right: 50px;
		bottom: 25px;
	}

	.bg-element {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;

		background-image: url('/img/background-hero-new-3.jpg');
		background-size: cover;
		background-position: center top;
	}

	@media only screen and (max-width: 940px) {
		padding: 30vw 50px;
		.bigtitle {
			font-size: 35px;
		}
	}
	@media only screen and (max-width: 600px) {
		padding: 50vw 50px;
		.bigtitle {
			font-size: 30px;
			top: 8vw;
			left: 50%;
			width: 100%;
			transform: translate(-50%, 0);
			text-align: center;
		}
		.main-button {
			left: 50%;
			transform: translate(-50%, 0);
			bottom: 25px;
			width: 150px;
			padding: 15px 10px;
			font-size: 14px;

			&.double {
		      &:before {
		        left: -22px;
		        width: 24px;
		        height: 58px;
		      }

		      &:after {
		        right: -22px;
		        width: 24px;
		        height: 58px;
		      }
		    }
		}
	}
}

.main-hero {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10vw 80px;
	position: relative;
	overflow: hidden;

	background-image: url('/img/background-hero.jpg');
	background-size: cover;
	background-position: center top;

	.data {
		text-align: center;
		width: 30%;

		.bigtitle {
			font-size: 4.2vw;
			margin-bottom: 30px;
		}

		.jus {
			display: none;
		}
	}

	.feuilles-left {
		position: absolute;
		top: 10vw;
		left: -3vw;
		width: 12vw;
		z-index: 100;
	}

	.feuilles-right {
		position: absolute;
		top: 10vw;
		right: -3vw;
		width: 14vw;
	}

	.jus {
		position: relative;
		display: flex;
		width: 30%;

		&.left {
			left: 2vw;
			img {
				position: relative;
				width: 13vw;

				&.left-1 {
					left: -4vw;
				}
				&.left-2 {
					left: -8vw;
				}
				&.left-3 {
					left: -12vw;
				}
			}
		}
		&.right  {
			justify-content: flex-end;
			img {
				position: relative;
				width: 13vw;

				&.right-1 {
					right: -9vw;
				}
				&.right-2 {
					right: -5vw;
				}
				&.right-3 {
					right: -1vw;
				}
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		padding: 10vw 50px;
	}

	@media only screen and (max-width: 1100px) {
		.jus {
			&.left {
				img {
					&:nth-child(1) {
						left: 0vw;
					}
					&:nth-child(2) {
						left: -3vw;
					}
					&:nth-child(3) {
						display: none;
					}
				}
			}
			&.right {
				img {
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2) {
						right: 0;
					}
					&:nth-child(3) {
						right: 3vw;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 820px) {
		.data {
			width: 40%;
			.bigtitle {
				margin-bottom: 20px;
			}
		}
		.jus {
			&.left {
				img {
					&:nth-child(1) {
						left: -5vw;
					}
					&:nth-child(2) {
						left: -8vw;
					}
					&:nth-child(3) {
						display: none;
					}
				}
			}
			&.right {
				img {
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2) {
						right: -4vw;
					}
					&:nth-child(3) {
						right: -1vw;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 670px) {
		background-image: url(/img/background-hero.jpg);
		background-size: 140%;
		background-position: center top;
		background-repeat: no-repeat;
		background-color: $lightGrey;

		.data {
			width: 100%;

			.bigtitle {
				font-size: 6vw;
			}

			.jus {
				display: flex;
				justify-content: center;
				width: 100%;
				margin-bottom: 20px;
				img {
					width: 27vw;
					position: relative;
					&.left  {
						right: -2vw;
					}
					&.right {
						left: -2vw;
					}
				}
			}
		}
		.jus {
			display: none;
		}
		.feuilles-left {
			position: absolute;
			top: 25vw;
			left: -3vw;
			width: 22vw;
			z-index: 100;
		}

		.feuilles-right {
			position: absolute;
			top: 33vw;
			right: 0;
			width: 18vw;
		}
	}
}

.categories-wrap {
	width: 100%;
	padding: 5vw 50px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.title-wrap {
		width: 100%;
		text-align: center;
		margin-bottom: 50px;
	}

	.categorie {
		width: 280px;
		height: 280px;
		margin: 10px;
		margin-top: 0;
		text-align: center;
		background-size: cover;
		background-position: center center;
		text-decoration: none;
		color: $colorAccent1;
		font-family: $fontTitle;
		font-size: 30px;
		border-radius: 40px;
		overflow: visible;
		position: relative;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-40%);
			width: auto;
			height: 175px;
			opacity: 0;
			transition: all 0.4s ease;
		}

		&:hover {
			.text-wrap {
				opacity: 0;
			}
			img {
				transform: translate(-50%,-50%);
				opacity: 1;
			}
		}

		svg {
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(40%, -50%);
			z-index: 9;

			@media (max-width: 767px) {
				transform: scale(0.55) translate(20px, -20px);
			}
		}
	}

	.text-wrap {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;
		transition: all 0.2s ease;
		border-radius: 40px;

		p {
			font-size: 35px;
		}
	}

	.categorie-1 {
		background-image: url('/img/categorie-1.jpg');
		.text-wrap {
			background-color: $colorAccent2;
		}
	}
	.categorie-2 {
		background-image: url('/img/categorie-3.jpg');
		.text-wrap {
			background-color: $colorAccent3;
		}
	}
	.categorie-3 {
		background-image: url('/img/categorie-4.jpg');
		.text-wrap {
			background-color: $colorAccent4;
		}
	}
	.categorie-4 {
		background-image: url('/img/categorie-2.jpg');
		.text-wrap {
			background-color: $colorAccent5;
		}
	}

	@media only screen and (max-width: 1300px) {
		.categorie {
			font-size: 25px;
			width: 240px;
			height: 240px;

			img {
				height: 155px;
			}
		}
		.text-wrap {
			p {
				font-size: inherit;
			}
		}
	}
	@media only screen and (max-width: 1140px) {
		.categorie {
			font-size: 20px;
			width: 200px;
			height: 200px;

			img {
				height: 145px;
			}
		}
	}
	@media only screen and (max-width: 980px) {
		padding: 50px 30px;
		justify-content: space-between;
		.categorie {
			width: calc(50% - 10px);
			margin: 0;
			margin-bottom: 20px;
			height: 240px;

			img {
				height: 145px;
			}
		}
	}
	@media only screen and (max-width: 550px) {
		padding: 30px 20px 10px 20px;
		.categorie {
			height: 175px;
			border-radius: 10px;

			.text-wrap {
				border-radius: 10px;
			}
		}
	}
}

.top-arguments {
	position: relative;
	overflow: hidden;

	width: 100%;
	padding: 50px;
	padding-top: 0;

	.title-wrap {
		width: 100%;
		text-align: center;
		margin-bottom: 80px;
	}

	.arguments-wrap {
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		padding: 130px 50px;
	}

	.bg-element {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;

		background-image: url('/img/arguments-background.jpg');
		background-size: cover;
	}

	.argument {
		width: 33.33%;
		padding: 0 4vw;
		text-align: center;

		img {
			width: auto;
			height: 100px;
			display: inline-block;
			margin-bottom: 20px;
		}

		h3 {
			margin-bottom: 10px;
			color: $colorAccent3;
		}

		p {
			color: $colorAccent3;
			line-height: 1.5;
		}
	}

	.main-button {
		display: inline-block;
		margin: 0 auto;
		margin-top: 50px;
	}

	@media only screen and (max-width: 1100px) {
		.arguments-wrap {
			padding: 100px 50px;
		}
		.argument {
			img {
				height: 70px;
			}
		}
	}
	@media only screen and (max-width: 940px) {
		.arguments-wrap {
			padding: 70px 50px;
		}
		.argument {
			padding: 10px;
			img {
				height: 50px;
			}
			h3 {
				font-size: 20px;
			}
		}
	}
	@media only screen and (max-width: 800px) {
		padding: 0 50px 50px 50px;
	}
	@media only screen and (max-width: 630px) {
		.argument {
			width: 100%;
		}
	}
	@media only screen and (max-width: 550px) {
		padding: 30px 20px;
		.arguments-wrap {
			padding: 50px;
		}
	}
}

.news-listing {
	position: relative;
	width: 100%;
	padding: 5vw 9vw;
	text-align: center;

	&.no-pt {
		padding-top: 0;
	}

	.title-wrap {
		width: 100%;
		text-align: center;
		margin-bottom: 40px;
	}

	.news-items {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;

		.news-item {
			width: calc(96% / 3);
			margin-bottom: 30px;
			margin-right: 2%;

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media (max-width: 767px) {
				width: 100%;
				margin-right: 0;

				&:nth-child(3n) {
					margin-right: 0;
				}
			}

			&:hover {
				.img-wrap {
					img {
						transform: scale(1.1);
					}
				}
			}

			.img-wrap {
				position: relative;
				overflow: hidden;
				margin-bottom: 30px;
				border-radius: 30px;

				img {
					display: block;
					width: 100%;
					transform: scale(1);
					transition: transform 0.6s ease-in-out;
				}

				&:hover {
					img {
						transform: scale(1.1);
					}
				}
			}

			a {
				text-decoration: none;
				color: $colorAccent1;
			}

			.label {
				font-family: $fontMenu;
				font-size: 13px;
				font-weight: 700;
				letter-spacing: 0.65px;
				text-transform: uppercase;

				&.color-variant {
					color: #B68150;
				}
			}
			.title {
				font-family: $fontTitle;
				font-size: 25px;
				font-weight: 700;
				padding-top: 15px;
				padding-bottom: 15px;
			}
			.text {
				font-family: $fontText;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}

	.main-button {
		display: inline-block;
		margin: 0 auto;
		margin-top: 50px;
	}
}

.news-listing.with-bg {
	position: relative;
	color: #3D441D;
	
	&:before {
		content: '';
		position: absolute;
		top: -3vw;
		right: 3vw;
		bottom: 50%;
		left: 3vw;
		opacity: 0.20000000298023224;
		background: #3D441D;
		z-index: -1;

		@media (max-width: 767px) {
			display: none;
		}
	}
}

.side-by-side {
	width: 100%;
	padding: 5vw 9vw;
	display: flex;
	align-items: center;

	.img-wrap {
		position: relative;
		width: 45%;

		.pastille {
			width: 17vw;
			position: absolute;
			top: -5vw;
			left: -5vw;
			border-radius: 0;
		}

		img {
			border-radius: 30px;
		}
	}

	.data {
		text-align: left;
		width: 55%;
		padding: 0 4vw 0 5.5vw;

		@media (max-width: 767px) {
			text-align: center;
		}

		.mediumtitle {
			font-size: 35px;
			margin-bottom: 30px;
		}

		.regulartext {
			margin-bottom: 30px;
			line-height: 2;
		}

		svg {
			margin-bottom: 20px;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 35px;
			flex-wrap: wrap;

			font-size: 16px;
			font-weight: 700;
			line-height: 250%;
			letter-spacing: 0.8px;
			text-transform: uppercase;
			font-family: $fontMenu;

			li {
				width: 50%;
				padding-bottom: 0;
			}
		}
	}

	@media only screen and (max-width: 1100px) {
		.data {
			padding: 0 5vw;
			.mediumtitle {
				font-size: 26px;
				margin-bottom: 25px;
			}
		}
	}
	@media only screen and (max-width: 700px) {
		flex-wrap: wrap;
		.img-wrap {
			width: 100%;
			margin-bottom: 40px;
			.pastille {
				width: 25vw;
				position: absolute;
				top: -5vw;
				left: -5vw;
				border-radius: 0;
			}
		}
		.data {
			width: 100%;
			margin-bottom: 30px;
		}
	}
}

.bottles-wrap {
	width: 100%;
	background-image: url('/img/rose.jpg');
	background-size: cover;
	display: flex;
	align-items: center;
	padding: 4vw 13vw;

	.img-wrap {
		width: 50%;
		position: relative;
		display: flex;
		justify-content: flex-end;

		.bottles {
			max-width: 475px;
		}

		.promo-wrap {
			position: absolute;
			top: 3vw;
			left: -70px;
			width: 14vw;

			.smalltitle {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				font-size: 2vw;
				text-align: center;
			}
		}
	}

	.data-wrap {
		width: 50%;
		text-align: center;

		.mediumtitle {
			font-size: 40px;
			margin-bottom: 2vw;
		}

		.caracteristiques {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 2vw 0;

			span {
				width: 10px;
				height: 10px;
				background-color: $colorAccent2;
				border-radius: 5px;
				margin: 0 10px;
			}
		}

		.regulartext {
			padding: 0 5vw;
		}
	}

	@media only screen and (max-width: 1100px) {
		padding: 5vw 50px;
		.img-wrap {
			.promo-wrap {
				left: 0px;
			}
		}
	}
	@media only screen and (max-width: 900px) {
		.data-wrap {
			.mediumtitle {
				font-size: 30px;
			}
		}
	}
	@media only screen and (max-width: 780px) {
		flex-wrap: wrap;
		padding: 50px 20px;
		.img-wrap {
			width: 100%;
			justify-content: center;
			.bottles {
				width: 70%;
				margin: 0 auto;
			}
			.promo-wrap {
				top: 3vw;
				left: 0;
				width: 30vw;

				.smalltitle {
					font-size: 4vw;
				}
			}
		}
		.data-wrap {
			width: 100%;
			padding-left: 0;

			.caracteristiques {
				margin: 5vw 0;
			}
		}
	}
	@media only screen and (max-width: 550px) {
		.data-wrap {
			.mediumtitle {
				font-size: 26px;
			}
			.caracteristiques {
				flex-wrap: wrap;

				p {
					width: 100%;
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}

				span {
					display: none;
				}
			}
		}
	}
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 55px;
    padding-bottom: 110px;

    a,
    .current {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        min-width: 35px;
        max-width: 35px;
        height: 35px;
        min-height: 35px;
        max-height: 35px;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 50%;
        background: #fff;
        color: #3D441D;
        cursor: pointer;
        transition: background 0.35s ease 0s, transform 0.35s ease 0s;
		text-decoration: none;

		font-size: 14px;
		font-family: $fontText;
		font-weight: 600;
		line-height: 1;

        span {
            position: relative;
        }

        &:hover {
            background: #B68150;
			color: #fff;
        }
    }

    .current {
        background: #B68150;
        color: #fff;
        cursor: default;

        &:hover {
            background: #000;
            transform: none;
        }
    }

    .prev-next-btn {
        background: #e2e2e2
    }
}

.no-results {
	width: 100%;
	text-align: center;
}

.hero-recette {
	position: relative;
	width: 100%;

	padding: 0 50px;
	margin-bottom: 3vw;

	display: flex;
	align-items: stretch;
	justify-content: space-between;

	@media (max-width: 900px) {
		flex-direction: column;
	}

	.left-content {
		width: 50%;
		background: #E7DDC4;
		color: #3D441D;
		padding: 10vw 3vw;

		@media (max-width: 900px) {
			width: 100%;
			padding: 10vw 5vw;
			order:1;
		}

		.label {
			font-family: $fontMenu;
			display: inline-block;
			color: #B68150;
			font-size: 13px;
			font-weight: 700;
			line-height: 1;
			letter-spacing: 0.65px;
			text-transform: uppercase;
			padding-bottom: 15px;

			&.bigger {
				text-transform: none;
				font-size: 20px;
				font-family: $fontTitle;
			}
		}
		
		.bigtitle {
			font-size: 40px;
			font-weight: 700;
			line-height: 110%;
			padding-bottom: 20px;

			@media (max-width: 900px) {
				font-size: 30px;
			}
		}
		.infos {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-bottom: 6vw;
		}
		.info {
			font-size: 14px;
			font-family: $fontText;
			font-weight: 700;
			line-height: 1;

			&:not(:last-child) {
				padding-right: 20px;
			}
		}

		.regulartext {
			font-size: 15px;
			font-weight: 600;
			line-height: 2;
		}
	}
	

	.right-images {
		width: 50%;

		@media (max-width: 900px) {
			width: 100%;
		}

		.carousel {
			width: 100%;
			height: 100%;

			@media (max-width: 900px) {
				height: auto;
			}
		}

		.main-carousel {
			height: 100%;

			@media (max-width: 900px) {
				height: auto;
			}
		}

		.carousel-cell {
			width: 100%;
			height: 100%;

			@media (max-width: 900px) {
				height: auto;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;

				@media (max-width: 900px) {
					min-height: 300px;
				}
			}
		}
	}

	.flickity-page-dots {
		bottom: 25px;
	}
}


.recette-infos {
	position: relative;
	width: 100%;
    padding: 3vw 7vw;

	.infos-row {
		display: flex;
		padding-top: 3vw;
		padding-bottom: 3vw;

		@media (max-width: 900px) {
			flex-direction: column;
		}

		.col {
			width: calc(100% / 3);

			@media (max-width: 900px) {
				width: 100%;
			}
		}

		.title {
			border-bottom: 1px solid #3D441D;
			padding-bottom: 25px;
			color: #3D441D;
			font-size: 30px;
			font-family: $fontTitle;
			font-weight: 700;
			line-height: 1;
			min-height: 56px;
			height: 56px;
			max-height: 56px;

			@media (max-width: 900px) {
				border-bottom: none;
				height: auto;
				min-height: auto;
				max-height: inherit;
				padding-top: 40px;
				font-size: 24px;
			}

			&.subtitle {
				font-family: $fontMenu;
				font-size: 18px;
				font-weight: 700;
				line-height: 1;
				letter-spacing: 0.9px;
				text-transform: uppercase;

				@media (max-width: 900px) {
					border-bottom: none;
					padding-bottom: 10px;
					padding-top: 20px;
					border-bottom: 1px solid #3D441D;
					font-size: 16px;
				}
			}
		}

		ul {
			margin: 0;
			padding: 0;
			padding-top: 35px;
			padding-bottom: 25px;

			color: #3D441D;
			font-size: 15px;
			font-family: $fontText;
			font-style: normal;
			font-weight: 600;
			line-height: 180%;

			list-style-type: none;

			@media (max-width: 900px) {
				padding-top: 14px;
				padding-bottom: 14px;
			}

			li {
				padding-top: 4px;
				padding-bottom: 4px;
				padding-right: 35px;

				display: flex;
				align-items: center;

				a {
					color: #ff564d;
				}

				.state {
					position: relative;
					cursor: pointer;
					width: 20px;
					height: 20px;
					margin-right: 20px;

					svg {
						position: absolute;
						top: 0;
						left: 0;
						width: 20px;
						opacity: 0;
					}

					&.off {
						.off {
							opacity: 1;
						}
					}

					&.on {
						.on {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

.end {
	display: flex;
	justify-content: space-between;
	padding-top: 3vw;
	padding-bottom: 3vw;

	@media (max-width: 900px) {
		flex-direction: column-reverse;
	}

	.related {
		width: 62%;

		@media (max-width: 900px) {
			width: 100%;
			padding-top: 3vw;
		}

		.product {
			text-align: center;
			width: 225px;
			
			@media (max-width: 900px) {
				width: 155px;
			}

			img {
				width: 225px;

				@media (max-width: 900px) {
					width: 155px;
				}
			}
			
			h4 {
				color: #3D441D;
				text-align: center;
				font-size: 20px;
				font-family: $fontTitle;
				font-weight: 700;
				line-height: 1;
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
	}

	.notes {
		width: 33%;

		@media (max-width: 900px) {
			width: 100%;
		}

		.text {
			font-size: 15px;
			font-family: $fontText;
			font-weight: 600;
			line-height: 180%;
		}
	}

	.title {
		border-bottom: 1px solid #3D441D;
		padding-bottom: 25px;
		color: #3D441D;
		font-size: 30px;
		font-family: $fontTitle;
		font-weight: 700;
		line-height: 1;
		min-height: 56px;
		height: 56px;
		max-height: 56px;
		margin-bottom: 40px;

		&.subtitle {
			font-size: 18px;
			font-weight: 700;
			line-height: 1;
			letter-spacing: 0.9px;
			text-transform: uppercase;
		}
	}
}