.hero-about-img {
	width: 100%;
	background-size: cover;
	text-align: center;
	padding: 15vw 50px;
	overflow: hidden;
	position: relative;

	&.empty {

		min-height: 40vw;

		.bg-element {
			background-image: url('/img/partenaires-new.jpg');
			background-position: center top;
		}
	}

	.bigtitle {
		font-size: 60px;
		color: #fff;
		position: relative;
		top: -5vw;
	}

	.bg-element {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;

		background-image: url('/img/hero-about-1-new.jpg');
		background-size: cover;
		background-position: center bottom;
	}

	@media only screen and (max-width: 940px) {
		padding: 20vw 50px;
		.bigtitle {
			font-size: 45px;
		}
	}
	@media only screen and (max-width: 550px) {
		padding: 20vw 20px;
		.bigtitle {
			font-size: 30px;
		}
	}
}

.hero-about-spacing {
	padding: 0 5vw;
	margin-bottom: 50px;
}

.hero-about-data {
	width: 100%;
	background-image: url('/img/bleu.jpg');
	margin: 0 auto;
	padding: 8vw 50px;
	margin-top: -10vw;
	border-radius: 35px;
	text-align: center;
	position: relative;

	&.partenaires {
		background-image: none;
		background-color: #fff;
	}

	.bigtitle {
		font-size: 50px;
		margin-bottom: 30px;
	}

	.regulartext {
		width: 100%;
		max-width: 750px;
		margin: 0 auto;
	}

	.logos_bloc {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 5vw;
		.bloc {
			margin: 0 3vw;
			img {
				width: auto;
				height: 80px;
			}
		}

		@media only screen and (max-width: 700px) {
			flex-wrap: wrap;

			.bloc {
				width: 100%;
				text-align: center;

				img {
					height: 45px;
					display: inline-block;
					margin-bottom: 20px;
				}
			}
		}
	}

	@media only screen and (max-width: 940px) {
		.bigtitle {
			font-size: 40px;
		}
	}
	@media only screen and (max-width: 550px) {
		.bigtitle {
			font-size: 30px;
		}
	}
}

.side-by-side {
	&.left {
		.data {
			padding: 0 0 0 5vw;

			.main-button {
				margin-left: 20px;
			}
		}
	}
	&.right {
		padding: 0vw 9vw 5vw 9vw;
		.data {
			padding: 0 10vw 0 4vw;
		}
	}

	@media only screen and (max-width: 700px) {
		&.left {
			.data {
				padding: 0;

				.main-button {
					margin-left: 0px;
				}
			}
		}
		&.right {
			.data {
				padding: 10px;
				order: 1;
			}
			.img-wrap {
				order: 0;
			}
		}
	}
}


.hero-about-data.partenaires {
	@media only screen and (max-width: 700px) {
		padding: 8vw 0;
    margin-top: 0;

    .bigtitle {
    	font-size: 26px;
    }
	}
}

.hero-about-img.empty {
	@media only screen and (max-width: 700px) {
		border-radius: 30px;
	}
}
