/**
 * components/global.css
 *
 * Project-wide styles
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
	background-color: #fff;
	color: $colorAccent1;
	line-height: 1;
}

p,h1,h2,h3,h4,h5,h6 {
	margin: 0;
	padding: 0;
}

img {
	width: 100%;
	height: auto;
	display: inherit;
}

.container {
  padding-top: 100px;
  @media only screen and (max-width: 940px) {
    padding-top: 80px;
  }
  @media only screen and (max-width: 550px) {
    padding-top: 70px;
  }
}

.fluid-container {
  padding: 1rem 20px;
}

.max-container {
  max-width: 1080px;
  margin: 0 auto;
}

::selection {
  background-color: $colorAccent1;
  color: #fff;
}

::-moz-selection {
  background-color: $colorAccent1;
  color: #fff;
}

.scroll-target {
  position: relative;
  top: -100px;
}
