

.logo {
  width: 120px;
  position: fixed;
  left: 100px;
  top: 20px;
  z-index: 2000000;

  transition: all 0.6s ease-in-out;

  @media only screen and (max-width: 940px) {
    display: none;
  }
}

.main-header {
  width: 100%;
  height: 100px;
  background-color: #fff;
  padding: 20px 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;

  .pastille-logo {
    width: 50px;
    opacity: 0;

    transition: all 0.6s ease-in-out;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a,
    .nav-link {
      text-decoration: none;
      color: $colorAccent1;
      font-family: $fontMenu;
      font-size: 13px;
      margin-left: 25px;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: all 0.2s ease;

      &.with-subnav {
        position: relative;
        display: flex;
        align-items: center;

        svg {
          margin-left: 10px;
        }
      }

      &.social-link {
        margin-left: 15px;
      }

      &.cta {
        font-family: $fontTitle;
        font-size: 24px;
        margin-left: 50px;
        text-transform: none;
        letter-spacing: 0;
      }

      &.lang {
        opacity: 0.5;
      }

      &.actif {
        color: #B0C4B9;
      }

      &:hover {
        color: #B0C4B9;

        &.lang {
          opacity: 1;
        }
      }
    }

    .social {
      width: 30px;
    }

    .subnav {
      position: absolute;
      top: 56px;
      left: 0;
      width: 230px;
      padding: 25px;
      color: #fff;
      background: $colorAccent1;

      display: flex;
      flex-direction: column;

      transform: translateY(20px);
      opacity: 0;
      z-index: -1;

      transition: transform 0.35s ease, opacity 0.35s ease, z-index 0.35s ease;

      height: 0;
      overflow: hidden;
      padding: 0;

      &.is-active {
        transform: translateY(0);
        opacity: 1;
        z-index: 1;

        height: auto;
        padding: 25px;
      }

      .subnav-link {
        color: #fff;
        font-size: 12px;
        font-family: $fontMenu;
        font-weight: 700;
        letter-spacing: 0.65px;
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
      }
    }
  }

  #hamburger {
    width: 30px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;
  }

  #hamburger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $colorAccent1;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  #hamburger span:nth-child(1) {
    top: 0px;
  }

  #hamburger span:nth-child(2),#hamburger span:nth-child(3) {
    top: 8px;
  }

  #hamburger span:nth-child(4) {
    top: 16px;
  }

  #hamburger.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #hamburger.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  @media only screen and (max-width: 980px) {
    padding: 10px 50px;
    height: 80px;

    #hamburger {
      display: block;
    }
    nav {
      display: none;
    }
    .pastille-logo {
      opacity: 1;
      width: 45px;
    }
  }

  @media only screen and (max-width: 550px) {
    padding: 10px 20px;
    height: 70px;

    .pastille-logo {
      width: 35px;
    }
  }
}

.mobile-menu-wrap {
  width: 100%;
  position: relative;
  z-index: -1000;
  transition: all 0.2s ease;

  &.open {
    z-index: 900;
    .overlay {
      opacity: 1;
    }
    .mobile-menu {
      right: 0;
      opacity: 1;
      z-index: 900;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.8);
    z-index: 800;
    opacity: 0;
    transition: all 0.2s ease;
  }

  .mobile-menu {
    position: fixed;
    top: 80px;
    right: -100%;
    width: 40vw;
    height: calc(100vh - 80px);
    background-color: $lightGrey;
    z-index: -1;
    padding: 40px;
    transition: all 0.2s ease;
    opacity: 0;
    text-align: center;

    @media only screen and (max-width: 900px) {
      width: 50vw;
    }
    @media only screen and (max-width: 700px) {
      width: 60vw;
    }
    @media only screen and (max-width: 550px) {
      width: 100vw;
      padding: 40px 20px;
      top: 70px;
      height: calc(100vh - 70px);
    }
  }

  .mobile-menu-logo {
    width: 25vw;
    margin-bottom: 40px;
  }

  nav {
    a {
      color: $colorAccent1;
      display: block;
      font-size: 14px;
      padding: 12px 0;
      transition: all 0.2s ease;
      font-family: $fontMenu;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-decoration: none;
      background-color: rgba(255, 255 , 255, 0.45);
      margin-bottom: 10px;
      padding: 15px;
      border-radius: 10px;

      &.lang {
        color: $colorAccent1;
        background-color: transparent;
        position: absolute;
        bottom: 40px;
        left: 40px;
        padding: 0;
        margin-bottom: 0;
        @media only screen and (max-width: 550px) {
          left: 30px;
        }
      }

      &.actif {
        color: $colorAccent2;
        background-color: #fff;
      }

      &:hover {
        color: $colorAccent2;
        background-color: #fff;
        &.lang {
          background-color: transparent;
        }
      }
    }
  }
  .cta {
    font-size: 16px;
    display: block;
    padding: 15px;
    background-color: $colorAccent1;
    text-align: center;
    font-family: $fontTitle;
    color: #fff;
    text-transform: none;
  }
  .social {
    position: absolute;
    bottom: 40px;
    right: 40px;
    display: flex;
    img {
      width: 25px;
      margin-left: 15px;
    }
  }
}
