.main-button {
  position: relative;
  color: $colorAccent1;
  text-decoration: none;
  font-family: $fontTitle;
  font-size: 22px;
  padding: 20px 10px;
  display: inline-block;
  transition: all 0.2s ease;
  backface-visibility: hidden;

  &.double {

    &:before {
      left: -31px;
      width: 33px;
      height: 83px;
    }

    &:after {
      right: -31px;
      width: 33px;
      height: 83px;
    }
  }

  &.simple {

    &:before {
      left: -23px;
      width: 25px;
      height: 62px;
    }

    &:after {
      right: -23px;
      width: 25px;
      height: 62px;
    }
  }

  &.white {
    background-color: #fff;
    &:before {
      background-image: url('/img/button-left.svg');
    }

    &:after {
      background-image: url('/img/button-right.svg');
    }
  }

  &.beige {
    background-color: $colorAccent6;
    &:before {
      background-image: url('/img/button-left-beige.svg');
    }

    &:after {
      background-image: url('/img/button-right-beige.svg');
    }
  }

  &.vert {
    background-color: #CBDDD3;
    &:before {
      background-image: url('/img/button-left-vert.svg');
    }

    &:after {
      background-image: url('/img/button-right-vert.svg');
    }
  }

  &:hover {
    transform: scale(1.1,1.1);
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 940px) {
    font-size: 16px;

    &.double {

      &:before {
        left: -27px;
        width: 29px;
        height: 72px;
      }

      &:after {
        right: -27px;
        width: 29px;
        height: 72px;
      }
    }

    &.simple {

      &:before {
        left: -20px;
        width: 22px;
        height: 56px;
      }

      &:after {
        right: -20px;
        width: 22px;
        height: 56px;
      }
    }
  }
}

.main-button-2 {
  position: relative;
  color: $colorAccent1;
  text-decoration: none;
  font-family: $fontTitle;
  font-size: 22px;
  padding: 20px 35px;
  background-color: $colorAccent6;
  border-radius: 10px;
  z-index: 100;
  transition: all 0.2s ease;

  p {
    position: relative;
    z-index: 100;
  }

  &:hover {
    transform: scale(1.1,1.1);
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: -15px;
    margin-left: -45px;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background-color: $colorAccent6;
    z-index: 10;
  }
  @media only screen and (max-width: 940px) {
    font-size: 16px;
    padding: 15px 30px;

    &:before {
      width: 70px;
      height: 70px;
      top: -13px;
      margin-left: -35px;
    }
  }
}

.main-button-3 {
  position: relative;
  background-color: #CBDDD3;
  color: $colorAccent1;
  text-decoration: none;
  font-family: $fontTitle;
  font-size: 22px;
  padding: 20px 10px;
  display: inline-block;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1,1.1);
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -20px;
    top: 0;
    background-image: url('/img/button-2-left.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 21px;
    height: 62px;
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: -20px;
    top: 0;
    background-image: url('/img/button-2-right.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 21px;
    height: 62px;
  }

  @media only screen and (max-width: 940px) {
    font-size: 16px;
    padding: 15px 30px;

    &:before {
      left: -13px;
      width: 15px;
      height: 46px;
    }

    &:after {
      right: -13px;
      width: 15px;
      height: 46px;
    }
  }
}

.main-button-4 {
  position: relative;
  background-color: #CBDDD3;
  color: $colorAccent1;
  text-decoration: none;
  font-family: $fontTitle;
  font-size: 22px;
  padding: 20px;
  display: inline-block;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1,1.1);
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -22px;
    top: 0;
    background-image: url('/img/button-3-left.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    height: 59px;
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: -22px;
    top: 0;
    background-image: url('/img/button-3-right.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    height: 59px;
  }
}
