.hero-contact {
	width: 100%;
	text-align: center;
	padding: 12vw 50px;
	overflow: hidden;
	position: relative;

	.bigtitle {
		font-size: 60px;
		color: #fff;
	}

	.bg-element {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;

		background-image: url('/img/hero-contact-new.jpg');
		background-size: cover;
		background-position: center bottom;
	}

	@media only screen and (max-width: 940px) {
		padding: 20vw 50px;
		.bigtitle {
			font-size: 45px;
		}
	}
	@media only screen and (max-width: 550px) {
		padding: 20vw 20px;
		.bigtitle {
			font-size: 30px;
		}
	}
}

.coordonnees {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 50px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	a {
		color: $colorAccent1;
		text-decoration: none;
		display: flex;
		align-items: center;

		img {
			width: auto;
			height: 30px;
			margin-right: 10px;
		}
	}

	@media only screen and (max-width: 1300px) {
		padding: 50px;
	}
	@media only screen and (max-width: 1000px) {
		a {
			.smalltitle {
				font-size: 14px;
			}
			img {
				height: 20px;
			}
		}
	}
	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		a {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}

.contact-wrap {
	width: 100%;
	padding: 5vw 9vw;
	display: flex;
	.google-map{
		width: 50%;
	}
	.form-wrap {
		width: 50%;
		text-align: center;
		padding: 0 7vw;

		.mediumtitle {
			font-size: 50px;
			margin-bottom: 30px;
		}

		input[type='text'],
		input[type='email'] {
			width: 100%;
			padding: 15px;
			border: 1px solid #E4E4E4;
			margin-bottom: 20px;
			border-radius: 15px;
			font-family: $fontText;
			color: $colorAccent1;

			&::placeholder {
				color: #959595;
			}
		}

		textarea {
			width: 100%;
			max-width: 100%;
			height: 175px;
			max-height: 175px;
			padding: 15px;
			border: 1px solid #E4E4E4;
			margin-bottom: 20px;
			border-radius: 15px;
			font-family: $fontText;
			color: $colorAccent1;

			&::placeholder {
				color: #959595;
			}
		}

		button {
			border:0;
			-webkit-appearance: none;
			font-size: 17px;
			cursor: pointer;
		}
	}

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		padding: 0vw 50px;
		.google-map {
			width: 100%;
			margin-bottom: 50px;
		}
		.form-wrap {
			width: 100%;
			margin-bottom: 50px;
			.mediumtitle {
				font-size: 30px;
			}
		}
	}
	@media only screen and (max-width: 550px) {
		padding: 0vw 20px;

		.form-wrap {
			padding: 0 0;
		}
	}
}
