.hero-produits {
	width: 100%;
	text-align: center;
	padding: 18vw 50px;
	overflow: hidden;
	position: relative;

	&.hero-recettes {
		padding-top: 13vw;
		padding-bottom: 13vw;

		.bg-element {
			background-image: url('/img/hero-recettes.jpg');
		}
		
		@media only screen and (max-width: 940px) {
			padding: 20vw 50px;
		}
	}

	.bigtitle {
		font-size: 60px;
		color: #fff;
	}

	.bg-element {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;

		background-image: url('/img/hero-produits-2.jpg');
		background-size: cover;
		background-position: center top;
	}

	&.hero-erable {
		.bg-element {
			background-image: url('/img/erable-hero.jpg');
		}
	}

	@media only screen and (max-width: 940px) {
		padding: 20vw 50px;
		.bigtitle {
			font-size: 45px;
		}
	}
	@media only screen and (max-width: 550px) {
		.bigtitle {
			font-size: 30px;
		}
	}
}

.produits-categories {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 50px;
	margin-bottom: 50px;

	.categorie {
		width: 100px;
		height: 100px;
		border-radius: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		text-decoration: none;
		color: $colorAccent1;
		font-family: $fontTitle;
		margin: 10px;
		transition: all 0.2s ease;
		font-size: 16px;

		&.categorie-1 {
			background-color: $colorAccent2;
		}
		&.categorie-2 {
			background-color: $colorAccent4;
		}
		&.categorie-3 {
			background-color: $colorAccent5;
		}
		&.categorie-4 {
			background-color: $colorAccent3;
		}
		&:hover {
			transform: scale(1.1,1.1);
		}
	}

	&.recettes {
		.categorie {
			background: #B68150;
    		color: #fff;

			&.is-active {
				background: #3D441D;
			}
		}
	}

	.regulartext {
		width: 100%;
		text-align: center;
		padding: 20px 28vw;
	}

	@media only screen and (max-width: 600px) {
		margin-top: 30px;
		margin-bottom: 30px;

		.categorie {
			font-size: 12px;
			width: 75px;
			height: 75px;
			border-radius: 50%;
			margin-bottom: 30px;
			margin: 0 11px 30px 0;
		}
		.regulartext {
			padding: 0 30px;
		}
	}
}

.produits-wrap {
	padding: 0 50px;

	@media only screen and (max-width: 550px) {
		padding: 0 0;
	}
}

.produits {
	width: 100%;
	display: flex;
	justify-content: center;
	border-top: 1px solid #E4E4E4;
	padding-top: 50px;
	padding-bottom: 50px;

	.data {
		width: 40%;
		text-align: center;

		.mediumtitle {
			margin-bottom: 20px;
			font-size: 45px;
		}

		.regulartext {
			margin-bottom: 20px;
			line-height: 1.5;
			padding: 0 7vw;
		}

		.formats-wrap {
			margin-top: 30px;
		}
	}

	.liste-wrap {
		width: 50%;
		display: flex;
		flex-wrap: wrap;

		&[data-child-length="1"] {
			justify-content: center;
		}

		.produit {
			position: relative;
			width: 33.33%;
			text-align: center;
			padding: 1vw;
			text-decoration: none;
			color: $colorAccent1;
			margin-bottom: 20px;

			&:hover {
				&:after {
					transform: scale(1,1);
				}
				img {
					transform: scale(1.1,1.1);
				}
			}

			&:after {
				content: '';
				width: 220px;
				height: 220px;
				display: inline-block;
				border-radius: 110px;
				position: absolute;
				top: 75px;
				left: 50%;
				margin-left: -109px;
				z-index: 10;
				transform: scale(0,0);
				transition: all 0.2s ease;
			}

			img {
				width: 180px;
				position: relative;
				left: 50%;
    			margin-left: -97px;
				z-index: 100;
				transform: scale(1,1);
				transition: all 0.2s ease;
			}

			p {
				font-size: 16px;
				font-family: $fontTitle;
				margin-top: 20px;
				line-height: 1.5;
			}

			.float-pastille {
				width: 70px;
				height: auto;
			
				position: absolute;
				top: 0;
				left: 0;
			
				.smalltitle {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					font-size: 12px;
					text-align: center;
					margin: 0;
					padding: 0;
					z-index: 15;
				}
			
				img {
					display: block;
					width: 100%;
					margin: 0;
					left: auto;

					z-index: 5;
				}
			}

			.float-pastille-p {
				width: 63px;
				height: 63px;
				background: $colorAccent1;
				border-radius: 50%;
			
				position: absolute;
				bottom: 80px;
				right: 0;

				z-index: 999;
				
				@media (max-width: 599px) {
					bottom: 80px;
					right: 0px;
				}
			
				.smalltitle {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					font-size: 12px;
					text-align: center;
					margin: 0;
					padding: 0;
					z-index: 15;
					color: $colorAccent6;
				}

				img {
					opacity: 0;
				}
			}
		}
	}

	&.categorie-1 {
		.liste-wrap {
			.produit {
				&:after {
					background-color: $colorAccent2;
				}
			}
		}
	}
	&.categorie-2 {
		.liste-wrap {
			.produit {
				&:after {
					background-color: $colorAccent4;
				}
			}
		}
	}
	&.categorie-3 {
		.liste-wrap {
			.produit {
				&:after {
					background-color: $colorAccent5;
				}
			}
		}
	}
	&.categorie-4 {
		.liste-wrap {
			.produit {
				&:after {
					background-color: $colorAccent3;
				}
			}
		}
	}

	@media only screen and (max-width: 1040px) {
		.liste-wrap {
			width: 60%;
			.produit {
				width: 50%;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		.data {
			width: 100%;
			margin-bottom: 30px;
			.mediumtitle {
				font-size: 30px;
			}
		}
		.liste-wrap {
			width: 100%;
			.produit {
				width: 50%;

				img {
					width: 140px;
	    			margin-left: -78px;
				}

				&:hover {
					&:after {
						transform: scale(0,0);
					}
					img {
						transform: scale(1,1);
					}
				}
			}
		}
	}
}


.formats-wrap {

	/* @media only screen and (max-width: 550px) {
		display: none;
	} */

	.formats-title {
		font-family: $fontTitle;
		font-size: 18px;
		margin-bottom: 10px;
	}

	.formats {
		display: flex;
		justify-content: center;
		align-items: flex-end;

		&.action {
			.format {
				svg path {
					cursor: pointer;
				}
				&.active {
					svg path {
						fill:$colorAccent1;
					}
					p {
						color: $colorAccent1;
						opacity: 1;
					}
				}
				&:hover {
					svg path {
						fill:$colorAccent1;
					}
					p {
						color: $colorAccent1;
						opacity: 1;
					}
				}
			}
		}

		.format {
			margin: 10px;

			svg path {
				fill:#e4e4e4;
				transition: all 0.2s ease;
			}

			p {
				font-family: $fontTitle;
				font-size: 12px;
				margin-top: 10px;
				color: #b1b1b1;
			}
		}
	}
}

.hero-produit {
	width: 100%;
	background-size: cover;
	text-align: center;
	padding: 10vw 50px;
	overflow: hidden;
	position: relative;

	.bigtitle {
		font-size: 60px;
		color: $colorAccent1;
		line-height: 1;
	}

	.categorie {
		margin-bottom: 20px;
	}

	.bg-element {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-size: cover;
	}

	@media only screen and (max-width: 940px) {
		.bigtitle {
			font-size: 45px;
		}
	}
	@media only screen and (max-width: 750px) {
		padding: 10vw 50px 40vw 50px;
	}
	@media only screen and (max-width: 550px) {
		.bigtitle {
			font-size: 30px;
		}
	}
}

.produit-infos {
	display: flex;
	justify-content: space-between;
	padding: 100px 10vw 50px 10vw;

	.formats-wrap {
		display: block;
	}

	.description {
		width: 33.33%;

		.mediumtitle {
			margin-bottom: 10px;
			font-size: 24px;
		}

		.smalltitle {
			margin-top: 50px;
			margin-bottom: 10px;
		}
	}

	.formats-wrap {
		width: 33.33%;
		text-align: center;
	}

	.img-wrap {
		position: relative;
		width: 30%;
		margin-top: -15vw;
	}

	&.smaller {
		.img-wrap {
			width: 20%;
		}
	}

	.placeholder {
		opacity: 0;
	}

	.imgs-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		z-index: 2;

		img {
			position: absolute;
			bottom: 0;
			left: 0;

			z-index: 3;

			opacity: 0;
			transform: translate(0, 20px);

			transition: all 0.45s ease-in-out;
		}
	}

	.img-wrap.format1 {
		.imgs-container img.format1 {
			opacity: 1;
			transform: none;
		}
	}

	.img-wrap.format2 {
		.imgs-container img.format2 {
			opacity: 1;
			transform: none;

		}
	}

	.img-wrap.format3 {
		.imgs-container img.format3 {
			opacity: 1;
			transform: none;
		}
	}

	@media only screen and (max-width: 750px) {
		flex-wrap: wrap;
		.description {
			width: 100%;
			order:2;
			.mediumtitle {
				font-size: 18px;
			}
			.smalltitle {
				margin-top: 30px;
			}
		}
		.formats-wrap {
			width: 100%;
			order:1;
			margin-bottom: 30px;
		}
		.img-wrap {
			width: 100%;
			order:0;
			margin-top: -45vw;
			margin-bottom: 25vw;

			img {
				width: 40vw;
			}

			&.smaller {
				.img-wrap {
					width: 20%;
				}
			}
		}
		.imgs-container {
			img {
				top: 0;
				left: 50%;
				margin-left: -22vw;
			}
		}
	}

	@media only screen and (max-width: 550px) {
		padding: 50px 30px;
	}
}

.valeurs-nutritives {
	padding: 0 50px 50px 50px;
	margin-top: 5vw;

	.toggle-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		.line {
			width: calc(50% - 140px);
			height: 1px;
			background-color: #E4E4E4;
		}

		.toggle {
			position: relative;
			cursor: pointer;

			&:hover,
			&.active {
				img {
					opacity: 1;
				}
			}

			.for-default {
				display: block;
			}

			.for-active {
				display: none;
			}

			&.active {
				.for-default {
					display: none;
				}
				.for-active {
					display: block;
				}
			}

			.smalltitle {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				width: 170px;
				z-index: 100;
			}
			img {
				width: 280px;
				opacity: 0;
				transition: all 0.2s ease;
				position: relative;
				z-index: 10;
			}
		}
	}

	.infos-wrap {
		width: 100%;
		border-radius: 30px;
		padding: 0;
		display: flex;
		justify-content: center;
		background-color: transparent;
		position: relative;
		overflow: hidden;
		max-height: 0;
		opacity: 0;

		transition: all 0.45s ease-in-out;

		&.active {
			padding: 50px;
			max-height: max-content;
			opacity: 1;
		}

		.table {
			width: 100%;
			max-width: 1000px;
			background-color: #F3F3F3;
			border-radius: 20px;
			padding: 30px;
		}

		header {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $colorAccent1;
			padding-bottom: 10px;
			margin-bottom: 10px;
		}

		.row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			border-bottom: 1px solid rgba(0,0,0,0.1);
			padding-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.col {
			width: 25%;
			text-align: center;

			.regulartext {
				font-size: 14px;
			}
		}
	}

	@media only screen and (max-width: 600px) {
		padding: 0 30px 50px 30px;
		.infos-wrap {
			.table {
				padding: 0;
				padding-top: 20px;
			}
			&.active {
				padding: 0;
			}
			.col {
				padding: 0 8px;
			}
			.smalltitle {
				font-size: 13px;
			}
		}
	}
}

.autre-produits {
	margin-bottom: 100px;
	margin-top: 50px;
	.title-wrap {
		width: 100%;
		text-align: center;
		margin-bottom: 50px;
	}
	.autre-produits-liste {
		display: flex;
		justify-content: space-between;
		justify-content: center;
		width: 100%;
		max-width: 1080px;
		margin: 0 auto;

		.produit {
			position: relative;
			width: 33.33%;
			text-align: center;
			padding: 1vw;
			text-decoration: none;
			color: $colorAccent1;

			&:hover {
				&:after {
					transform: scale(1,1);
				}
				img {
					transform: scale(1.1,1.1);
				}
			}

			&:after {
				content: '';
				width: 220px;
				height: 220px;
				display: inline-block;
				border-radius: 110px;
				position: absolute;
				top: 75px;
				left: 50%;
				margin-left: -109px;
				z-index: 10;
				transform: scale(0,0);
				transition: all 0.2s ease;
			}

			&.les-jus-du-verger {
				&:after {
					background-color: $colorAccent2;
				}
			}
			&.le-jus-biologique {
				&:after {
					background-color: $colorAccent3;
				}
			}
			&.la-limonade {
				&:after {
					background-color: $colorAccent4;
				}
			}
			&.le-thé-glacé {
				&:after {
					background-color: $colorAccent5;
				}
			}

			img {
				width: 180px;
				position: relative;
				left: 50%;
    			margin-left: -97px;
				z-index: 100;
				transform: scale(1,1);
				transition: all 0.2s ease;
			}

			p {
				font-size: 16px;
				font-family: $fontTitle;
				margin-top: 20px;
				line-height: 1.5;
			}
		}
	}

	@media only screen and (max-width: 1040px) {
		.autre-produits-liste {
			flex-wrap: wrap;
			.produit {
				width: 50%;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		.data {
			width: 100%;
			margin-bottom: 30px;
			.mediumtitle {
				font-size: 30px;
			}
		}
		.autre-produits-liste {
			width: 100%;
			padding: 0 30px;
			.produit {
				width: 50%;
				margin-bottom: 20px;

				img {
					width: 140px;
	    			margin-left: -78px;
				}

				&:hover {
					&:after {
						transform: scale(0,0);
					}
					img {
						transform: scale(1,1);
					}
				}
			}
		}
	}
}

.produits-erable-wrap {
	position: relative;
	padding-top: 3vw;
	padding-bottom: 3vw;

	@media (max-width: 767px) {
		padding-top: 45px;
		padding-bottom: 45px;
	}

	.side-by-side {
		padding-top: 3vw;
		padding-bottom: 3vw;

		.img-wrap {
			border-radius: 0;

			img {
				border-radius: 0;
			}
		}
	}

	.infos {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.right {
			margin-left: auto;

			@media (max-width: 767px) {
				margin: 0;
			}
		}
	}

	.formats-wrap .formats {
		justify-content: flex-start;

		@media (max-width: 767px) {
			justify-content: center;
		}
	}
}