/** --------------------------------------------------
/** Variables
/** -------------------------------------------------- **/

/** Fonts **/
@font-face {
    font-family: 'OpenSans';
    src: url('/dist/fonts/opensans-semibold-webfont.eot');
    src: url('/dist/fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/dist/fonts/opensans-semibold-webfont.woff2') format('woff2'),
        url('/dist/fonts/opensans-semibold-webfont.woff') format('woff'),
        url('/dist/fonts/opensans-semibold-webfont.ttf') format('truetype'),
        url('/dist/fonts/opensans-semibold-webfont.svg#opensans-semibold-webfont') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('/dist/fonts/recoleta-bold-webfont.eot');
    src: url('/dist/fonts/recoleta-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/dist/fonts/recoleta-bold-webfont.woff2') format('woff2'),
        url('/dist/fonts/recoleta-bold-webfont.woff') format('woff'),
        url('/dist/fonts/recoleta-bold-webfont.ttf') format('truetype'),
        url('/dist/fonts/recoleta-bold-webfont.svg#recoleta-bold-webfont') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Coco';
    src: url('/dist/fonts/coco-gothic-small-caps-bold-webfont.eot');
    src: url('/dist/fonts/coco-gothic-small-caps-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/coco-gothic-small-caps-bold-webfont.woff2') format('woff2'),
        url('/dist/fonts/coco-gothic-small-caps-bold-webfont.woff') format('woff'),
        url('/dist/fonts/coco-gothic-small-caps-bold-webfont.ttf') format('truetype'),
        url('/dist/fonts/coco-gothic-small-caps-bold-webfont.svg#coco-gothic-small-caps-bold-webfont') format('svg');
    font-weight: bold;
    font-style: normal;
}

$fontText:    'OpenSans', 'Arial', 'sans-serif';
$fontTitle:   'Recoleta', 'Arial', 'sans-serif';
$fontMenu:    'Coco', 'Arial', 'sans-serif';

/** Accents Colors **/
$colorAccent1:  #3D441D;
$colorAccent2:  #FF564D;
$colorAccent3:  #BFEADA;
$colorAccent4:  #F0E77B;
$colorAccent5:  #8AD09D;
$colorAccent6:  #E7DDC4;

$lightGrey: #c1dccd;

