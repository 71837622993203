.bigtitle {
  font-family: $fontTitle;
  line-height: 1;
}

.mediumtitle {
  font-family: $fontTitle;
  font-size: 30px;
  line-height: 1.1;

  @media only screen and (max-width: 1100px) {
    font-size: 26px;
  }
}

.smalltitle {
  font-family: $fontTitle;
  font-size: 18px;
  line-height: 1.1;
}

.regulartext {
  font-family: $fontText;
  font-size: 16px;
  line-height: 1.5;

  @media only screen and (max-width: 1100px) {
    font-size: 14px;
  }
}
